a {
  text-decoration: none !important;
}

.uET-bg-contain {
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.uET-bg-cover {
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.uET-gap-216 {
  gap: 150px;
}

.uET-gap-100 {
  gap: 100px;

  @media only screen and(max-width: 992px) {
    gap: 50px;
  }
}

.uET-gap-24 {
  gap: 24px;
}

.uET-gap-32 {
  gap: 32px;
}

.uET-gap-16 {
  gap: 16px;
}

.uET-gap-8 {
  gap: 8px;
}

@media only screen and (max-width: 992px) {
  .uET-gap-216 {
    gap: 100px;
  }
}

.uET-input {
  background-color: white;
  border: 1px solid #fb651b;
  border-radius: 5px;
  height: 50px;
}

.uET-button {
  border-radius: 5px;
  height: 50px;
}

.uET-form-invalid {
  border: 2px solid red;
}

.uET-main-border {
  border: 1px solid #fb651b;
  padding: 3rem;
}

.ET-time-container {
  padding: 3rem;
}

@media all and (max-width: 446px) {
  .ET-time-container {
    padding: 1rem;
  }
}

.uET-gray-border {
  border: 1px solid rgba(0, 0, 0, 4.6%);
}

.uET-f-radius-s5 {
  border-radius: 5px;
}

.uET-f-radius-s20 {
  border-radius: 20px;
}

.uET-f-radius-s40 {
  border-radius: 40px;
}

.uET-radius-s50 {
  border-radius: 50%;
}

.uET-click {
  cursor: pointer;
}

.uET-tra-2 {
  transition: all ease-in-out 200ms;
}

.uET-opc-1 {
  opacity: 1 !important;
}

.iti {
  width: 100% !important;
  text-align: right;
}

.iti-mobile .iti--container {
  direction: ltr;
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 0;
  position: fixed;
}

.iti--allow-dropdown input {
  width: 100% !important;
}

.iti__selected-flag {
  padding: 8px !important;
  direction: ltr;
}

.iti__flag {
  background-image: url("../images/flags.webp");
}

@media (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../images/flags.webp");
  }
}

.modal-content {
  background-color: inherit !important;
  border: none !important;
}

.hello {
  display: none !important;
}

.country-dropdown {
  direction: ltr;
}

/* Importing Bootstrap SCSS file. */

/* Webinar Form CSS */
.ET-form-bg {
  background-image: linear-gradient(to left, rgba(251, 101, 27, 90%), rgba(255, 166, 46, 90%)) !important;
}

.ET-formbg {
  width: 174px;
  height: 177px;
  z-index: 0;
}

.ET-time-picker-btn-tag {
  width: 90px;
}

/* About us */
.ET-general-english-image-holder {
  max-width: 450px;
  width: 100%;
  height: 400px;
}

.ET-business-english-image-holder {
  max-width: 450px;
  width: 100%;
  height: 400px;
}

.ET-general-english-holder {
  @media only screen and (max-width: 992px) {
    font-size: 21px;
  }

  font-size: 32px;
  font-weight: bold;
}

.ET-business-english-holder {
  @media only screen and (max-width: 992px) {
    font-size: 21px;
  }

  font-size: 32px;
  font-weight: bold;
}

/* Counter */
.ET-map-s {
  height: 360px;
  width: 100%;
  right: 0;
  top: -100px;
}

/* Student feedback */
.ET-image-holder {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: absolute;
  top: -100px;

  @media only screen and(max-width: 1200px) {
    width: 160px;
    height: 160px;
    top: -80px;
  }

  @media only screen and(max-width: 992px) {
    width: 160px;
    height: 160px;
    top: -60px;
  }
}

.ET-reviewCard-holder {
  min-width: 216px;
  max-width: 370px;
  height: 420px;
  padding-top: 120px;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 16%);

  @media only screen and(max-width: 992px) {
    height: 380px;
    padding-top: 120px;
  }
}

/* Partners */
.ET-logo-holder {
  min-width: 200px;
  height: 100px;
}

/* Header */
.ET-logo-s {
  width: 201px;
  height: 50px;
}

.ET-header-rating-holder {
  width: 170px;
  height: 30px;
}

/* Rating */
.ET-rating-holder {
  max-width: 236px;
}

.ET-rating-section-holder {
  height: 400px;
}

.ET-Rating-space {
  padding: 20px 32px;
}

.ET-icon-space {
  width: 28px;
  height: 27px;
}

/* Registration Process */
.ET-registration-image-holder {
  min-width: 200px;
  max-width: 600px;
  min-height: 460px;
}

/* Services */
.ET-Certificate-holder {
  height: 120px;
  max-width: 350px;
  width: 100%;
}

.ET-title-holder {
  @media only screen and(min-width: 992px) {
    width: 80%;
  }

  @media only screen and(max-width: 1198px) {
    width: 100%;
  }
}
