.imET-registerProcess {
  background-image: url("../images/registerProcess.webp");
}

.imET-business-english {
  background-image: url("../images/Businessenglish.webp");
}

.imET-general-english {
  background-image: url("../images/Generalenglish.webp");
}

.imET-Ratingsection {
  background-image: url("../images/Ratingsection.svg");
}

.imET-Reem {
  background-image: url("../images/Reem.webp");
}

.imET-Mohamed {
  background-image: url("../images/Mohamed.webp");
}

.imET-Hassan {
  background-image: url("../images/Hassan.webp");
}

.imET-Dots {
  background-image: url("../images/Dots.svg");
}

.imET-Certificate {
  background-image: url("../images/Certificate.webp");
}

.imET-englease-logo {
  background-image: url("../images/Engleaselogo.webp");
}

.imET-omar {
  background-image: url("../images/omar.webp");
}

.imET-BGForm {
  background-image: url("../images/BGForm.svg");
}

.imET-formBg {
  background-image: url("../images/formBg.svg");
}

.imET-map {
  background-image: url("../images/Map.webp");
}

.imET-widgetbg {
  background-image: url("../images/widgetbg.svg");
}

.imET-ratingNav {
  background-image: url("../images/ratingNav.webp");
}

.iti__flag {
  background-image: url("../images/flags.webp") !important;
}

.imET-ABB {
  background-image: url("../images/ABB.webp");
}

.imET-Mobily {
  background-image: url("../images/Mobily.webp");
}

.imET-RajhiBank {
  background-image: url("../images/RajhiBank.webp");
}

.imET-Nokia {
  background-image: url("../images/Nokia.webp");
}

.imET-FM {
  background-image: url("../images/FM.webp");
}

.imET-ArabComputers {
  background-image: url("../images/ArabComputers.webp");
}

.imET-Zain {
  background-image: url("../images/Zain.webp");
}

.imET-Sephora {
  background-image: url("../images/Sephora.webp");
}
