.fET-s100 {
  font-size: 100px;
}

.fET-bold-s71 {
  font-size: 60px;
  font-weight: bold;
  line-height: 60px;

  @media only screen and (max-width: 992px) {
    font-size: 48px;
    line-height: 48px;
  }
}

.fET-bold-s48 {
  font-size: 48px;
  font-weight: bold;
  line-height: 72px;
}

.fET-s56 {
  font-size: 56px;
}

.fET-s46-lh60 {
  @media only screen and (max-width: 992px) {
    font-size: 27px;
  }

  font-size: 46px;
}

.fET-s46 {
  font-size: 46px;
  line-height: 36px;
}

.fET-s32 {
  font-size: 36px;
}

.fET-s34 {
  font-size: 34px;

  @media only screen and(max-width: 992px) {
    font-size: 27px;
  }
}

.fET-bold-s36 {
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
}

.fET-bold-s24 {
  font-size: 24px;
  font-weight: bold;
}

.fET-bold-s21 {
  font-size: 21px;
  font-weight: bold;
  line-height: 36px;
}

.fET-reg-s18 {
  font-size: 18px;
}

.fET-reg-s16 {
  font-size: 16px;
  line-height: 26px;
}

.fET-bold-s12 {
  font-size: 12px;
  font-weight: bold;
}

.fET-reg-s8 {
  font-size: 8px;
}

.fET-b {
  font-family: TajawalBold, Helvetica, Arial, sans-serif;
}

.fET {
  font-family: Tajawal, Helvetica, Arial, sans-serif;
}

@media (max-width: 992px) {
  .fET-s32 {
    font-size: 24px;
  }

  .fET-s56 {
    font-size: 36px;
  }

  .fET-s46 {
    font-size: 27px;
  }

  .fET-bold-s71 {
    font-size: 60px;
    font-weight: bold;
    line-height: 40px;
  }
}

@font-face {
  font-family: Tajawal;
  src: local("Tajawal Regular"), local("Tajawal-Regular"), url("./Tajawal-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: TajawalBold;
  src: local("Tajawal Bold"), local("Tajawal-Bold"), url("./Tajawal-Bold.ttf");
  font-display: swap;
}

body {
  font-family: Tajawal, Helvetica, Arial, sans-serif;
}
