.iET-board {
  background-image: url("../images/Board.svg");
}

.iET-Lessons {
  background-image: url("../images/Lessons.svg");
}

.iET-Cup {
  background-image: url("../images/Cup.svg");
}

.iET-Invest {
  background-image: url("../images/Invest.svg");
}

.iET-Facebookgray {
  background-image: url("../images/Facebookgray.svg");
}

.iET-Instagramgray {
  background-image: url("../images/Instagramgray.svg");
}

.iET-Tiktokgray {
  background-image: url("../images/Tiktokgray.svg");
}

.iET-Youtubegray {
  background-image: url("../images/Youtubegray.svg");
}

.iET-Starempty {
  background-image: url("../images/Starempty.svg");
}

.iET-Starfill {
  background-image: url("../images/Starfill.svg");
}

.iET-badge {
  background-image: url("../images/badge.svg");
}

.iET-closeCross {
  background-image: url("../images/closeCross.svg");
}

.iET-VideoPlayButton {
  background-image: url("../images/VideoPlayButton.svg");
}

.iET-Whatsapp {
  background-image: url("../images/whatsapp.webp");
}

.iET-sA {
  display: block;
  min-width: 87px;
  height: 70px;
}

.iET-sB {
  display: block;
  min-width: 32px;
  height: 32px;
}

.iET-sBB {
  display: block;
  min-width: 48px;
  height: 48px;
}

.iET-sC {
  display: block;
  min-width: 24px;
  height: 24px;
}

.iET-sD {
  display: block;
  min-width: 16px;
  height: 16px;
}
