.cET-main-bg {
  background-color: #fb651b !important;
}

.cET-blue-bg {
  background-color: #2e4d91 !important;
}

.cET-blue-01-bg {
  background-color: #a4bef6 !important;
}

.cET-main-txt {
  color: #fb651b !important;
}

.cET-blue-txt {
  color: #2e4d91 !important;
}

.cET-gray-txt {
  color: #000 !important;
  opacity: 0.4;
}

.cET-bg-gradient-main {
  background-image: linear-gradient(to left, rgba(251, 101, 27, 90%), rgba(255, 166, 46, 90%)) !important;
}
