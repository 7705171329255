@import "/src/styles/ET-lib/colors.scss";
@import "/src/styles/ET-lib/fonts.scss";
@import "/src/styles/ET-lib/icons.scss";
@import "/src/styles/ET-lib/images.scss";
@import "/src/styles/ET-lib/utilities.scss";
@import "intl-tel-input/build/css/intlTelInput.css";
@import "~bootstrap/scss/bootstrap";

.ET-unselected-time {
  border: 1px solid #fb651b;
  color: #fb651b !important;
}

.ET-selected-time {
  background-color: #fb651b;
  color: white;

  &:hover {
    color: white;
  }
}
